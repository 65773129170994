<template>
  <div class="card">
    <router-link :to="{ name: 'userDetail', params: { id: data.uid, data: data }}">
      <div class="card-content">
        <div class="media">
          <div class="media-left">
            <figure class="image is-64x64">
              <img
                class="is-rounded profile-image"
                :src="data.profile_image_url"
                loading="lazy"
                alt="profile image"
                @error="fallbackProfileImage"
              >
            </figure>
          </div>
          <div class="media-content">
            <h3 class="is-size-5">
              {{ data.display_name }}
            </h3>
            <p class="is-size-7">
              @{{ data.tag_name }}
            </p>
            <div class="media-footer">
              <div class="stat is-flex">
                <span class="icon">
                  <img
                    src="@/assets/lollypop.svg"
                    alt="like icon"
                  >
                  {{ formatNumberCommas(data.total_likes) }}
                </span>
                <span class="icon">
                  <img
                    src="@/assets/gift.svg"
                    alt="gift icon"
                  >
                  {{ formatNumberCommas(data.total_gifts) }}
                </span>
                <span class="icon">
                  <img
                    v-if="data.agency"
                    class="badge-image"
                    :src="data.agency.badge_url"
                    :title="data.agency.name"
                    alt="badge-image"
                  >
                  <img
                    v-if="data.rank"
                    class="badge-image"
                    :src="data.rank.image_url"
                    :title="data.rank.name"
                    alt="badge-image"
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { formatNumberCommas } from '@/utils'

export default {
  name: 'LiveCard',
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  methods: {
    formatNumberCommas (number) {
      return formatNumberCommas(number)
    },
    fallbackProfileImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    },
    getThumbnailImage (url) {
      if (url) {
        return url
      }
      return 'https://space.pillowcast.app/image/default/logo.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  color: #ffffff;
  border: solid 1px rgba(255, 255, 255, 0.07);
  background: rgba(255, 255, 255, 0.05);
  box-shadow: none;
  a {
    color: #ffffff;
  }
  p {
    color: rgba(255, 255, 255, 0.5);
  }
  &:hover {
    a, p {
      color: #000000;
    }
    .stat img {
      filter: invert(1);
    }
    border: solid 1px rgba(255, 255, 255, 0.5);
    color: #000000;
    background: rgba(255, 255, 255, 0.95);
    transition: all 0.25s ease-out;
    box-shadow:
    0 0 5px #fff,
    -20px 0 100px 5px #f0f,
    0 0 20px 1px #ff0,
    20px 0 100px 5px #0ff,
    0 0 20px 1px #0f0;
    animation: glowRotate 10s ease infinite;
  }
}

.card-content {
  padding: 10px;
  h3 {
    line-height: 1.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
  }
  .media-footer {
    img {
      margin-right: 3px;
      opacity: .75;
    }
    .stat {
      font-size: 0.75rem;
      align-items: center;
      margin-bottom: 0.5rem;
      @media screen and (max-width: 767px) {
        font-size: 0.75rem;
        margin: 0;
      }
      .icon {
        width: auto;
        margin-right: 10px;
        img {
          margin-right: 5px;
        }
      }
    }
  }
}

@keyframes glowRotate {
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  0% {
    background-position: 0% 50%;
  }
}
</style>
